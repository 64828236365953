
<template>
  <navigation index="accountManage" ref="navigation">
    <div :style="{ height: ContentHeight + 'px' }">
      <div class="topnavigation" style="gap: 10px;">
        <el-button
          style="margin-left: 10px"
          type="primary"
          @click="AddAccount()"
          >新增</el-button
        >

        <el-input
          v-model="SearchText"
          style="width: 320px"
          placeholder="请输入名称或则账号进行搜索"
          @keyup.enter="GetStoreUserList"
        ></el-input>
        <el-button type="primary" @click="GetStoreUserList">
          <el-icon class="no-inherit">
            <Search />
          </el-icon>
          搜索</el-button
        >
      </div>

      <el-table
        border
        :data="AccountDatas"
        style="width: 100%"
        :height="TableHeight + 'px'"
      >
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column label="帐号" width="220">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-icon>
                <timer />
              </el-icon>
              <span style="margin-left: 10px">{{ scope.row.Account }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="姓名" width="180" prop="Name">
        </el-table-column>
        <el-table-column label="所属店铺" width="180" prop="StoreName">
        </el-table-column>
        <el-table-column label="手机" width="180" prop="Phone">
        </el-table-column>
        <el-table-column label="邮箱" width="180" prop="Email">
        </el-table-column>
        <el-table-column label="账号状态" width="100" prop="ForbiddenFormat">
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="300">
          <template #default="scope">
            <el-button
              type="primary"
              size="small"
              @click="AccountEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              size="small"
              v-if="!scope.row.Forbidden"
              @click="UserForbidden(scope.$index, scope.row)"
              type="danger"
              >禁用</el-button
            >
            <el-button
              size="small"
              v-if="scope.row.Forbidden"
              @click="UserRemoveForbidden(scope.$index, scope.row)"
              type="success"
              >解禁</el-button
            >
            <el-button
              size="small"
              @click="AccountDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div style="margin: 5px">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="CurrentChange"
          :page-size="padding.Size"
          :total="padding.Count"
        >
        </el-pagination>
      </div>
    </div>
  </navigation>

  <!-- 修改新增用户 -->
  <el-dialog
    v-model="EditOrAddWindow"
    :title="IsAdd ? '新增' : '修改'"
    width="800px"
    :before-close="EditOrAddWindowClose"
    :show-close="false"
  >
    <el-form
      ref="form"
      label-width="120px"
      v-model="AccountModel"
      :model="AccountModel"
      :rules="AccountRules"
    >
      <el-form-item label="所属商店">
        <span style="font-size: 17pt"> {{ StoreOpt.Name }}</span>
        <el-button
          @click="StoreUserBindWindow = true"
          style="margin-left: 30px"
          type="primary"
          size="small"
          >选择</el-button
        >
      </el-form-item>
      <el-form-item label="姓名" prop="Name">
        <el-input v-model="AccountModel.Name"></el-input>
      </el-form-item>
      <el-form-item label="帐号" prop="Account">
        <el-input v-model="AccountModel.Account"></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-col :span="11">
          <el-input v-model="AccountModel.Password"></el-input>
        </el-col>
        <el-col :span="2"> </el-col>
        <el-col :span="11"> 不修改请留空 </el-col>
      </el-form-item>
      <el-form-item label="手机">
        <el-input v-model="AccountModel.Phone"></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="Email">
        <el-input v-model="AccountModel.Email"></el-input>
      </el-form-item>
      <el-form-item label="账号角色">
        <el-select
          v-model="AccountModel.RolesArray"
          multiple
          placeholder="请选择账号角色"
        >
          <el-option label="员工" value="StoreUser">员工</el-option>
          <el-option label="管理员" value="StoreAdmin">管理员</el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="EditOrAddWindowClose">关闭</el-button>
        <el-button type="primary" @click="EditOrAddSave()">确定</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 商店关联 -->
  <el-dialog
    v-model="StoreUserBindWindow"
    title="关联商店"
    width="800px"
    :before-close="EditOrAddWindowClose"
    :draggable="true"
    :show-close="false"
  >
    <el-tabs
      v-model="StoreUserBindWindowActiveName"
      class="demo-tabs"
      @tab-click="handleClick"
    >
      <el-table border :data="StoreDatas" style="width: 100%" :height="'500px'">
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column label="商户名称" prop="Name" width="280">
        </el-table-column>
        <el-table-column label="到期时间" prop="ExpireDate" width="180">
        </el-table-column>
        <el-table-column label="商户号" prop="DomainName" width="180">
        </el-table-column>
        <el-table-column label="备注" prop="Content" width="180">
        </el-table-column>
        <el-table-column label="邮箱" prop="Mailbox" width="180">
        </el-table-column>

        <el-table-column label="操作" fixed="right">
          <template #default="scope">
            <el-button
              type="success"
              size="small"
              @click="RelevanceStore(scope.row)"
              >选择</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-tabs>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="StoreUserBindWindow = !StoreUserBindWindow"
          >关闭</el-button
        >
      </span>
    </template>
  </el-dialog>
  <div class="content"></div>
</template>
<script >
import axios from "../../../commons/AxiosMethod.js";
import tools from "../../../commons/tools.js";
import "../../../assets/css/commons.css";
import { ElMessageBox, ElMessage } from "element-plus";
import navigation from "../../../components/Modules/AdminNavigation.vue";
export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    var validateEmail = (rule, value, callback) => {
      if (value != "") {
        var pattern =
          /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/;

        if (!pattern.test(value)) {
          callback(new Error("请输入正确的邮箱"));
        }
      } else {
        callback();
      }
    };

    return {
      TableHeight: document.documentElement.clientHeight - 120,
      ContentHeight: document.documentElement.clientHeight - 200,
      StoreList: [], //商店列表
      AccountDatas: [],
      AccountModel: {}, // 选中用户
      EditOrAddWindow: false, // 修改或者新增窗口
      IsAdd: false, // 是否是新增

      RoleDatas: [],

      NewPassword: "",
      UpdatePasswordDisplay: false,
      SearchText: "", //筛选信息
      padding: {
        Page: 1,
        Size: 30,
        Count: 0,
      },
      StorePadding: {
        where: {},
      },
      StoreUserBindWindow: false,
      StoreUserOpt: {}, //当前选中商店用户
      StoreUserBindWindowActiveName: "first",

      RelevanceStoreDatas: [], //已绑定列表

      InvoiceSignatureItems: [],

      StoreId: "", //商店ID
      StoreOpt: {}, //当前店铺
      AccountRules: {
        Name: [{ required: true, message: "请输入员工名称", trigger: "blur" }],
        Mailbox: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          {
            min: 4,
            max: 35,
            message: "长度在 3 到 35 个字符",
            trigger: "blur",
          },
        ],
        Account: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            min: 5,
            max: 35,
            message: "长度在 5 到 35 个字符",
            trigger: "blur",
          },
        ],
        Password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 5,
            max: 35,
            message: "长度在 5 到 35 个字符",
            trigger: "blur",
          },
        ],
        Email: [
          {
            validator: validateEmail,
            message: "请输入正确的邮箱格式",
            trigger: "blur",
          },
        ],
      },
    };
  },
  name: "App",
  mounted() {
    this.StoreId = this.$route.query.storeId;
    this.GetStoreDetail();
    this.GetStoreUserList();
    this.GetStoreList();

    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.TableHeight = document.documentElement.clientHeight - 120;
        this.ContentHeight = document.documentElement.clientHeight - 200;
        this.$refs.navigation.onresize();
      })();
    };
    this.TableHeight = document.documentElement.clientHeight - 120;
    this.ContentHeight = document.documentElement.clientHeight - 200;
  },
  components: { navigation },
  methods: {
    GetStoreDetail() {
      axios.apiMethod(
        "/admins/StoreMaintain/GetStoreDetail",
        "get",
        {
          storeId: this.StoreId,
        },
        (result) => {
          if (result.Data.IsSuccess) {
            this.StoreOpt = result.Data.StoreDetail;
          } else {
            this.StoreOpt = {};
            // ElMessage({
            //   message: result.Data.Msg,
            //   type: "error",
            // });
          }
        }
      );
    },
    //解禁禁用账户
    UserRemoveForbidden(index, item) {
      axios.apiMethod(
        "/admins/StoreUser/UserRemoveForbidden",
        "get",
        {
          Id: item.Id,
        },
        (result) => {
          if (result.Data.Success) {
            ElMessage({
              type: "success",
              dangerouslyUseHTMLString: true,
              message: result.Data.Msg,
            });

            this.GetStoreUserList();
          } else {
            ElMessage({
              dangerouslyUseHTMLString: true,
              message: result.Data.Msg,
            });
          }
        }
      );
    },
    //禁用账户
    UserForbidden(index, item) {
      axios.apiMethod(
        "/admins/StoreUser/UserForbidden",
        "get",
        {
          Id: item.Id,
        },
        (result) => {
          if (result.Data.Success) {
            ElMessage({
              type: "success",
              dangerouslyUseHTMLString: true,
              message: result.Data.Msg,
            });

            this.GetStoreUserList();
          } else {
            ElMessage({
              dangerouslyUseHTMLString: true,
              message: result.Data.Msg,
            });
          }
        }
      );
    },
    //取消关联
    CancelRelevanceStore(index, item) {
      axios.apiMethod(
        "/admins/StoreUser/CancelRelevanceStore",
        "get",
        {
          Id: item.Id,
        },
        (result) => {
          if (result.Data.Success) {
            ElMessage({
              type: "success",
              dangerouslyUseHTMLString: true,
              message: result.Data.Msg,
            });

            this.GetRelevanceStoreList();
          } else {
            ElMessage({
              dangerouslyUseHTMLString: true,
              message: result.Data.Msg,
            });
          }
        }
      );
    },
    //获取已关联列表
    GetRelevanceStoreList() {
      axios.apiMethod(
        "/admins/StoreUser/GetRelevanceStore",
        "get",
        {
          StoreUserId: this.StoreUserOpt.Id,
        },
        (result) => {
          this.RelevanceStoreDatas = result.Data;
        }
      );
    },
    //查询商店列表
    GetStoreList() {
      this.StorePadding.Where = {
        Name: "",
        Account: "",
      };

      axios.apiMethod(
        "/admins/StoreMaintain/GetStoreList",
        "post",
        this.padding,
        (result) => {
          this.StoreDatas = result.Data.Datas;
          this.StorePadding.Page = result.Data.Page;
          this.StorePadding.Size = result.Data.Size;
          this.StorePadding.Count = result.Data.Count;
        }
      );
    },
    //绑定商店
    RelevanceStore(item) {
      this.StoreOpt = item;
      // this.AccountModel.StoreId = item.Id;
      // this.AccountModel.StoreName = item.Name;
      this.StoreUserBindWindow = false;
    },

    //修改窗口
    EditOrAddWindowClose() {
      this.EditOrAddWindow = false;
    },
    // 保存
    EditOrAddSave() {
      const param = this.AccountModel;
      param.StoreId = this.StoreOpt.Id;
      axios.apiMethod(
        "/admins/StoreUser/CreateStoreUser",
        "post",
        param,
        (result) => {
          if (result.Data.IsSuccess) {
            this.EditOrAddWindowClose();
            this.GetStoreUserList();
          } else {
            ElMessage({
              message: result.Data.Msg,
              type: "error",
            });
          }
        }
      );
    },
    // 创建用户
    AddAccount() {
      this.AccountModel = {
        Email: "",
        Name: "",
        Phone: "",
        Password: "",
        Roles: "",
        StoreId: "",
      };
      this.IsAdd = true;
      this.EditOrAddWindow = true;
    },
    // 编辑
    AccountEdit(index, row) {
      this.EditOrAddWindow = true;
      this.AccountModel = row;
      this.IsAdd = false;
    },

    // 删除
    AccountDelete(index, row) {
      ElMessageBox.confirm("确定要删除吗？", "提示")
        .then(() => {
          axios.apiMethod(
            "/admins/StoreUser/DeleteAccounts?Id=" + row.Id,
            "get",
            {},
            (result) => {
              if (result.Code == 200) {
                ElMessageBox({
                  title: "提示",
                  message: "删除成功",
                  type: "success",
                });
                this.GetStoreUserList();
              }
            }
          );
        })
        .catch(() => {
          // catch error
        });
    },
    //刷新列表
    CurrentChange(index) {
      this.padding.Page = index;
      this.GetStoreUserList();
    },
    // 获取用户列表
    GetStoreUserList() {
      this.padding.Where = {
        Name: "",
        Account: "",
        StoreId: this.StoreId,
      };

      this.padding.Search = this.SearchText;

      axios.apiMethod(
        "/admins/StoreUser/GetStoreUserList",
        "post",
        this.padding,
        (result) => {
          if (result.Code == 200) {
            this.AccountDatas = result.Data.Datas;
            this.padding.Page = result.Data.Page;
            this.padding.Size = result.Data.Size;
            this.padding.Count = result.Data.Count;
          }
        }
      );
    },
  },
};
</script>

<style scoped></style>
